<template>
  <!-- <a-page-header @back="goBack" title="GESTANTES" :breadcrumb="{ routes }" class="px-0" /> -->
  <a-page-header @back="() => $router.go(-1)" title="TABLA NUTRICIONAL GESTANTES" class="pt-0 px-0">
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Nutricional</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>Tabla nutricional gestantes</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <a-tabs v-model:activeKey="activeKey" class="-mt-8 text-center">
    <a-tab-pane key="1" tab="GANANCIA DE PESO">
      <div class="flex mb-4">
        <fieldset class="border border-solid border-gray-300 p-3 px-8 w-full">
          <GestantesFiltros @fetchGestantes="fetchGestantes" />
        </fieldset>
      </div>
      <div class="overflow-x-auto w-full bg-white border-2 rounded-lg p-4">
        <a-table
          id="table-custom"
          size="small"
          rowKey="idgestantes"
          :bordered="false"
          :columns="columnsSemanaGestacion"
          :data-source="data_edad"
          :pagination="false"
          :loading="isLoading1"
          rowClassName="p-0"
        >
          <template #pesobajounicominimo="{ text: pesobajounicominimo }">
            <div class="bg-yellow-600 text-white px-3 py-3.5 -my-3 text-center">
              {{ pesobajounicominimo }}
            </div>
          </template>

          <template #pesobajounicomaximo="{ text: pesobajounicomaximo }">
            <div class="bg-yellow-600 text-white px-3 py-3.5 -my-3 text-center">
              {{ pesobajounicomaximo }}
            </div>
          </template>

          <template #semanasgestacion="{ text: semanasgestacion }">
            <div class="text-center px-3 py-3.5 -my-3 text-center font-black">
              {{ semanasgestacion }}
            </div>
          </template>

          <template #pesonormalunicominimo="{ text: pesonormalunicominimo }">
            <div class="bg-green-600 text-white px-3 py-3.5 -my-3 text-center">
              {{ pesonormalunicominimo }}
            </div>
          </template>

          <template #pesonormalunicomaximo="{ text: pesonormalunicomaximo }">
            <div class="bg-green-600 text-white px-3 py-3.5 -my-3 text-center">
              {{ pesonormalunicomaximo }}
            </div>
          </template>

          <template #pesonormalmellizosminimo="{ text: pesonormalmellizosminimo }">
            <div class="bg-green-200 px-3 py-3.5 -my-3 text-center">
              {{ pesonormalmellizosminimo }}
            </div>
          </template>

          <template #pesonormalmellizosmaximo="{ text: pesonormalmellizosmaximo }">
            <div class="bg-green-200 px-3 py-3.5 -my-3 text-center">
              {{ pesonormalmellizosmaximo }}
            </div>
          </template>

          <template #pesosobrepesounicominimo="{ text: pesosobrepesounicominimo }">
            <div class="bg-yellow-400 px-3 py-3.5 -my-3 text-center">
              {{ pesosobrepesounicominimo }}
            </div>
          </template>

          <template #pesosobrepesounicomaximo="{ text: pesosobrepesounicomaximo }">
            <div class="bg-yellow-400 px-3 py-3.5 -my-3 text-center">
              {{ pesosobrepesounicomaximo }}
            </div>
          </template>

          <template #pesosobrepesomellizosminimo="{ text: pesosobrepesomellizosminimo }">
            <div class="bg-yellow-400 px-3 py-3.5 -my-3 text-center">
              {{ pesosobrepesomellizosminimo }}
            </div>
          </template>

          <template #pesosobrepesomellizomaximo="{ text: pesosobrepesomellizomaximo }">
            <div class="bg-yellow-400 px-3 py-3.5 -my-3 text-center">
              {{ pesosobrepesomellizomaximo }}
            </div>
          </template>

          <!-- 4 -->
          <template #pesoobesaunicominimo="{ text: pesoobesaunicominimo }">
            <div class="bg-yellow-600 text-white px-3 py-3.5 -my-3 text-center">
              {{ pesoobesaunicominimo }}
            </div>
          </template>

          <template #pesoobesaunicomaximo="{ text: pesoobesaunicomaximo }">
            <div class="bg-yellow-600 text-white px-3 py-3.5 -my-3 text-center">
              {{ pesoobesaunicomaximo }}
            </div>
          </template>

          <template #pesoobesamellizosminimo="{ text: pesoobesamellizosminimo }">
            <div class="bg-yellow-400 px-3 py-3.5 -my-3 text-center">
              {{ pesoobesamellizosminimo }}
            </div>
          </template>

          <template #pesoobesamellizosmaximo="{ text: pesoobesamellizosmaximo }">
            <div class="bg-yellow-400 px-3 py-3.5 -my-3 text-center">
              {{ pesoobesamellizosmaximo }}
            </div>
          </template>
        </a-table>
      </div>
    </a-tab-pane>

    <a-tab-pane key="2" tab="CLASIFICACIÓN SEGÚN IMC">
      <div class="flex mb-4">
        <fieldset class="border border-solid border-gray-300 p-3 px-8 w-full">
          <GestantesFiltrosNutricional @fetchGestantesNutricional="fetchGestantesNutricional" />
        </fieldset>
      </div>
      <div class="overflow-x-auto w-full bg-white border-2 rounded-lg p-4">
        <a-table
          :bordered="true"
          :columns="columnsIMC"
          :data-source="data_talla"
          size="small"
          :pagination="false"
          rowKey="tallametro"
          :loading="isLoading1"
          :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-pink-200' : 'bg-gray-100')"
        >
          <template #title> </template>
          <template #tallametro="{ text: tallametro }">
            <span class="text-center p-4 font-black">
              {{ tallametro }}
            </span>
          </template>
        </a-table>
      </div>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import { ref, watch, reactive, onMounted, onUnmounted } from "vue";
import { columnsSemanaGestacion, columnsIMC } from "./utilsGestantes";
import { useStore } from "vuex";
import UsuariosApi from "@/api/user";
import GestantesApi from "@/api/gestantes";
import GestantesFiltros from "./GestantesFiltros.vue";
import GestantesFiltrosNutricional from "./GestantesFiltrosNutricional.vue";

export default {
  name: "Productora",
  components: {
    GestantesFiltros,
    GestantesFiltrosNutricional
  },
  setup() {
    const pageSize1 = ref(10);
    const pageSize2 = ref(10);
    const store = useStore();
    const curretPage1 = ref(1);
    const curretPage2 = ref(1);
    const totalDocs1 = ref(null);
    const totalDocs2 = ref(null);
    const isVisible = ref(false);
    const data_edad = ref([]);
    const data_talla = ref([]);
    const isLoading1 = ref(false);
    const isLoading2 = ref(false);
    const state = reactive({
      selectedRowKeys: [],
      rowActiveStatus: []
    });
    const isDisabled = reactive({
      edit: true,
      deleteAndChange: true
    });
    const { establecimiento } = store.state.user.usuario;

    // ------- trae datos de la api --------
    const fetchGestantes = () => {
      const search = store.getters["gestantesNutricional/search1"];

      isLoading1.value = true;
      GestantesApi.getAll(search)
        .then((response) => {
          data_edad.value = response.data;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading1.value = false));
    };

    const fetchGestantesNutricional = () => {
      const search = store.getters["gestantesNutricional/search2"];
      isLoading2.value = true;

      GestantesApi.getAllClasificacion(search)
        .then((response) => {
          data_talla.value = response.data;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading2.value = false));
    };

    onMounted(() => {
      UsuariosApi.userLogAdd("Nutricional", "Gestantes", establecimiento)
        .then(async () => {})
        .catch((err) => console.log(err));
      fetchGestantes();
      fetchGestantesNutricional();
    });

    onUnmounted(() => {
      store.dispatch("gestantesNutricional/cleanFilters");
    });

    watch(curretPage1, () => {
      fetchGestantes();
    });

    watch(curretPage2, () => {
      fetchGestantesNutricional();
    });

    watch(state, () => {
      isDisabled.edit = state.selectedRowKeys.length !== 1;
      isDisabled.deleteAndChange = !state.selectedRowKeys.length;
    });

    return {
      activeKey: ref("1"),
      columnsSemanaGestacion,
      columnsIMC,
      isVisible,
      isDisabled,
      data_edad,
      data_talla,
      pageSize1,
      pageSize2,
      curretPage1,
      curretPage2,
      isLoading1,
      isLoading2,
      totalDocs1,
      totalDocs2,
      fetchGestantes,
      fetchGestantesNutricional
    };
  }
};
</script>

<style scoped>
.ant-table-tbody {
  padding: 0 !important;
}
.ant-table-tbody > tr > td {
  padding: 0 !important;
}
</style>
