export const columnsSemanaGestacion = [
  {
    title: " Semanas de gestacion",
    dataIndex: "semanasgestacion",
    key: "semanasgestacion",
    slots: { customRender: "semanasgestacion" },
    width: 100
  },
  {
    title: "BAJOPESO kg.",
    children: [
      {
        title: "Único",
        children: [
          {
            title: "mín.",
            dataIndex: "pesobajounicominimo",
            key: "pesobajounicominimo",
            slots: { customRender: "pesobajounicominimo" }
          },
          {
            title: "máx.",
            dataIndex: "pesobajounicomaximo",
            key: "pesobajounicomaximo",
            slots: { customRender: "pesobajounicomaximo" }
          }
        ]
      }
    ]
  },
  {
    title: "NORMAL kg.",
    children: [
      {
        title: "Único",
        children: [
          {
            title: "mín.",
            dataIndex: "pesonormalunicominimo",
            key: "pesonormalunicominimo",
            slots: { customRender: "pesonormalunicominimo" }
          },
          {
            title: "máx.",
            dataIndex: "pesonormalunicomaximo",
            key: "pesonormalunicomaximo",
            slots: { customRender: "pesonormalunicomaximo" }
          }
        ]
      },
      {
        title: "Mellizos",
        children: [
          {
            title: "mín.",
            dataIndex: "pesonormalmellizosminimo",
            key: "pesonormalmellizosminimo",
            slots: { customRender: "pesonormalmellizosminimo" }
          },
          {
            title: "máx.",
            dataIndex: "pesonormalmellizosmaximo",
            key: "pesonormalmellizosmaximo",
            slots: { customRender: "pesonormalmellizosmaximo" }
          }
        ]
      }
    ]
  },
  {
    title: "SOBREPESO kg.",
    children: [
      {
        title: "Único",
        children: [
          {
            title: "mín.",
            dataIndex: "pesosobrepesounicominimo",
            key: "pesosobrepesounicominimo",
            slots: { customRender: "pesosobrepesounicominimo" }
          },
          {
            title: "máx.",
            dataIndex: "pesosobrepesounicomaximo",
            key: "pesosobrepesounicomaximo",
            slots: { customRender: "pesosobrepesounicomaximo" }
          }
        ]
      },
      {
        title: "Mellizos",
        children: [
          {
            title: "mín.",
            dataIndex: "pesosobrepesomellizosminimo",
            key: "pesosobrepesomellizosminimo",
            slots: { customRender: "pesosobrepesomellizosminimo" }
          },
          {
            title: "máx.",
            dataIndex: "pesosobrepesomellizomaximo",
            key: "pesosobrepesomellizomaximo",
            slots: { customRender: "pesosobrepesomellizomaximo" }
          }
        ]
      }
    ]
  },

  {
    title: "OBESA kg.",
    children: [
      {
        title: "Único",
        children: [
          {
            title: "mín.",
            dataIndex: "pesoobesaunicominimo",
            key: "pesoobesaunicominimo",
            slots: { customRender: "pesoobesaunicominimo" }
          },
          {
            title: "máx.",
            dataIndex: "pesoobesaunicomaximo",
            key: "pesoobesaunicomaximo",
            slots: { customRender: "pesoobesaunicomaximo" }
          }
        ]
      },
      {
        title: "Mellizos",
        children: [
          {
            title: "mín.",
            dataIndex: "pesoobesamellizosminimo",
            key: "pesoobesamellizosminimo",
            slots: { customRender: "pesoobesamellizosminimo" }
          },
          {
            title: "máx.",
            dataIndex: "pesoobesamellizosmaximo",
            key: "pesoobesamellizosmaximo",
            slots: { customRender: "pesoobesamellizosmaximo" }
          }
        ]
      }
    ]
  }
];

export const columnsIMC = [
  {
    title: "",
    children: [
      {
        title: "IMC",
        children: [
          {
            title: "Talla (m)",
            dataIndex: "tallametro",
            key: "tallametro",
            slots: { customRender: "tallametro" }
          }
        ]
      }
    ]
  },
  {
    title: "Bajo peso",
    children: [
      {
        title: "<18,5",
        children: [
          {
            title: "kg",
            dataIndex: "pesobajopesoimcmenor18",
            key: "pesobajopesoimcmenor18"
          }
        ]
      }
    ]
  },
  {
    title: "Normal",
    children: [
      {
        title: "18,5",
        children: [
          {
            title: "kg",
            dataIndex: "pesonormalimc18",
            key: "pesonormalimc18"
          }
        ]
      },
      {
        title: "24,9",
        children: [
          {
            title: "kg",
            dataIndex: "pesonormalimc24",
            key: "pesonormalimc24"
          }
        ]
      }
    ]
  },
  {
    title: "Sobrepeso",
    children: [
      {
        title: "25",
        children: [
          {
            title: "kg",
            dataIndex: "pesosobrepesoimc25",
            key: "pesosobrepesoimc25"
          }
        ]
      },
      {
        title: "29,9",
        children: [
          {
            title: "kg",
            dataIndex: "pesosobrepesoimc29",
            key: "pesosobrepesoimc29"
          }
        ]
      }
    ]
  },
  {
    title: "Obesa",
    children: [
      {
        title: ">30",
        children: [
          {
            title: "kg",
            dataIndex: "pesoobesaimcmayor30",
            key: "pesoobesaimcmayor30"
          }
        ]
      }
    ]
  }
];
