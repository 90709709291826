import { post } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";

export default {
  getAll: (search) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_migr_select_tvna_gestantes",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "semanasgestacion",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: search.length > 0 ? `${search}` : `%${search}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllClasificacion: (search) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_migr_select_tvna_gestanteclasificacion",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "tallametro",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${search}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  }
};
