<template>
  <a-form
    layout="horizontal"
    :model="formState"
    :rules="rules"
    ref="formRefFilter"
    class="flex flex-wrap md:flex-nowrap gap-3 items-center text-center justify-center mx-auto"
  >
    <a-form-item label="Buscar" class="mb-0" name="search">
      <a-input
        v-model:value="formState.search"
        style="width: 300px"
        placeholder="Busca por talla (m)"
      />
    </a-form-item>

    <button @click="applyFilters" type="submit" class="text-center px-3 ml-2">
      <div class="text-3xl text-blue-400">
        <SearchOutlined />
      </div>
      <p>Buscar</p>
    </button>

    <button @click="cleanFilters" type="button" class="text-center px-3" :disabled="isDisabled">
      <div
        class="text-3xl"
        :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-purple-300']"
      >
        <ClearOutlined />
      </div>
      <p>Limpiar filtros</p>
    </button>
  </a-form>
</template>
<script>
import { reactive, ref, toRaw, onMounted } from "vue";
import { useStore } from "vuex";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons-vue";

export default {
  components: {
    SearchOutlined,
    ClearOutlined
  },
  emits: ["fetchGestantesNutricional"],
  setup(props, { emit }) {
    const store = useStore();
    const formRefFilter = ref();
    const isDisabled = ref(true);

    const formState = reactive({
      search: store.getters["gestantesNutricional/setSearch2"]
    });

    const rules = {
      search: [
        {
          max: 50,
          message: "La cantidad máxima de caracteres es de 50",
          trigger: "blur"
        }
      ]
    };

    const applyFilters = () => {
      isDisabled.value = false;
      formRefFilter.value
        .validate()
        .then(async () => {
          const { search } = toRaw(formState);

          store.dispatch("gestantesNutricional/setSearch2", search);
          emit("fetchGestantesNutricional");
        })
        .catch((err) => console.log(err));
    };

    const cleanFilters = () => {
      isDisabled.value = true;
      formState.search = "";

      store.dispatch("gestantesNutricional/setSearch2", "");
      emit("fetchGestantesNutricional");
    };

    return {
      formState,
      applyFilters,
      formRefFilter,
      cleanFilters,
      rules,
      isDisabled
    };
  }
};
</script>
